<template>
  <ul class="pagination">
    <li class="page-item" :class="{'disabled': !hasPrev}">
      <span @click="first">&lt;&lt;</span>
    </li>
    <li class="page-item" :class="{'disabled': !hasPrev}">
      <span @click="prev">&lt;</span>
    </li>
    <li v-for="index in endPageIndex" :key="index" class="page-item">
      <span
        :class="{'cur': (startPage + index) === page}"
        @click="curPage = (startPage + index)"
      >
        {{ startPage + index }}
      </span>
    </li>
    <li class="page-item" :class="{'disabled': !hasNext}">
      <span @click="next">&gt;</span>
    </li>
    <li class="page-item" :class="{'disabled': !hasNext}">
      <span @click="last">&gt;&gt;</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: 'TechPagination',
  props: {
    totalElements: {
      type: Number,
      default() {
        return 0
      }
    },
    pageSize: {
      type: Number,
      default() {
        return 10
      }
    },
    rowsPerPage: {
      type: Number,
      default() {
        return 10
      }
    },
    page: {
      type: Number,
      default() {
        return 1
      }
    }
  },
  computed: {
    startPage() {
      return Math.floor((this.curPage - 1) / this.pageSize) * this.pageSize
    },
    endPageIndex() {
      let result = this.totalPages - this.startPage

      if (result > ((this.startPage + 1) + this.pageSize)) {
        result = this.pageSize
      } else if (result <= 0) {
        result = 1
      }
      return result
    },
    totalPages() {
      return Math.ceil(this.totalElements / this.rowsPerPage)
    },
    hasPrev() {
      return this.curPage > 1
    },
    hasNext() {
      return this.curPage < this.totalPages
    }
  },
  data() {
    return {
      curPage: 1
    }
  },
  watch: {
    curPage() {
      this.$emit('update:page', this.curPage)
    }
  },
  mounted() {
    this.curPage = this.page
  },
  methods: {
    first() {
      if (this.hasPrev) {
        this.curPage = 1
      }
    },
    prev() {
      if (this.hasPrev) {
        this.curPage--
      }
    },
    next() {
      if (this.hasNext) {
        this.curPage++
      }
    },
    last() {
      if (this.hasNext) {
        this.curPage = this.totalPages
      }
    },
  }
}
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
}
.pagination li.page-item {
  margin: 0 0.25rem;
}
.pagination li.page-item span {
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.pagination li.page-item.disabled span {
  color: #bbb;
  cursor: text;
}
.pagination li.page-item span.cur {
  font-weight: bold;
  font-size: 1.1rem;
}
</style>

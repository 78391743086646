<template>
  <v-container fluid>
    <p class="text-h5 text-left">업체 관리</p>
    <div class="autho">
      <select v-model="param.searchType" class="yon" style="background-color: white; -moz-appearance: caret; -webkit-appearance: caret">
        <option value="name">업체명</option>
        <option value="bizNo">사업자등록번호</option>
      </select>
      <div class="ml-2 shbtn01">
        <input v-model="param.keyword" type="text" class="code" @keydown.enter="search">
        <a @click="search"></a>
      </div>
    </div>

    <table class="popup-table mt-6" style="width: 100%">
      <colgroup>
        <col width="10%">
        <col width="10%">
        <col width="30%">
        <col width="20%">
        <col width="20%">
        <col width="10%">
      </colgroup>
      <thead>
      <tr>
        <th class="td_dark_blue">NO.</th>
        <th class="td_dark_blue pointer" @click="setSort('dbType')">데이터구분 <v-icon v-if="checkSort('dbType')" small>{{ checkSort('dbType') }}</v-icon></th>
        <th class="td_dark_blue pointer" @click="setSort('custName')">업체명 <v-icon v-if="checkSort('custName')" small>{{ checkSort('custName') }}</v-icon></th>
        <th class="td_dark_blue pointer" @click="setSort('registerNo')">사업자등록번호 <v-icon v-if="checkSort('registerNo')" small>{{ checkSort('registerNo') }}</v-icon></th>
        <th class="td_dark_blue pointer" @click="setSort('representName')">대표자명 <v-icon v-if="checkSort('representName')" small>{{ checkSort('representName') }}</v-icon></th>
        <th class="td_dark_blue pointer" @click="setSort('custCode')">거래처코드 <v-icon v-if="checkSort('custCode')" small>{{ checkSort('custCode') }}</v-icon></th>
      </tr>
      </thead>
      <thead v-if="loading">
      <tr>
        <th colspan="5" class="td_loading">
          <v-progress-linear
            indeterminate
            color="#0f2585"
          ></v-progress-linear>
        </th>
      </tr>
      </thead>
      <tbody v-if="items.length > 0">
      <template v-for="(item,index) in items">
        <tr :key="index" :class="selectedIndex === index ? 'selected' : ''">
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.rnum }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ getDbType(item.dbType) }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.custName }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.formattedRegisterNo }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.representName }}</td>
          <td class="td_blue cen bo" @click="itemSelect(item, index)">{{ item.custCode }}</td>
        </tr>
      </template>
      </tbody>
      <tbody v-else>
      <tr>
        <td class="no-data" colspan="5">데이터를 찾을 수 없습니다.</td>
      </tr>
      </tbody>

    </table>
    <tech-pagination :page.sync="pageable.page" :page-size="10" :total-elements="pageable.totalElements"/>

    <div class="userCont">
      <fieldset>
        <legend>업체정보</legend>
        <table width="100%" cellspacing="0" cellpadding="4px">
          <colgroup>
            <col width="13%">
            <col width="15%">
            <col width="14%">
            <col width="15%">
            <col width="12%">
            <col width="14%">
          </colgroup>
          <tbody>
          <tr>
            <th class="td_blue big">데이터구분</th>
            <td class="td_blue">{{ getDbType(selectedItem.dbType) }}</td>
          </tr>
          <tr>
            <th class="td_blue big">업체명</th>
            <td class="td_blue big">사업자등록번호</td>
            <td class="td_blue big">기관유형</td>
            <td class="td_blue big">업태</td>
            <td class="td_blue big">담당자명</td>
            <td class="td_blue big">이메일</td>
          </tr>
          <tr>
            <td class="td_blue"><input v-model="selectedItem.custName" type="text" style="width:96%" class="blue_input"></td>
            <td class="td_blue"><input v-model="selectedItem.registerNo" type="text" style="width:96%" class="blue_input"></td>
            <td class="td_blue">
              <select v-model="selectedItem.custType" class="select_input03">
                <option :value="null">기관유형을 선택해주세요.</option>
                <option v-for="item in comboItems['7050']" :value="item.codeDetailId" :key="item.codeDetailId">{{ item.codeDetailName }}</option>
              </select>
            </td>
            <td class="td_blue">
              <input v-model="selectedItem.industryClass" type="text" style="width:96%" class="blue_input">
            </td>
            <td class="td_blue">
              <input v-model="selectedItem.chargerName" type="tel" style="width:96%" class="blue_input">
            </td>
            <td class="td_blue">
              <input v-model="selectedItem.chargerEmail" type="email" style="width:96%" class="blue_input">
            </td>
          </tr>
          <tr>
            <th class="td_blue big">대표자명</th>
            <td class="td_blue big">국가</td>
            <td class="td_blue big">지역구분</td>
            <td class="td_blue big">업종</td>
            <td class="td_blue big">담당자연락처</td>
            <td class="td_blue big">휴대전화번호</td>
          </tr>
          <tr>
            <td class="td_blue">
              <input v-model="selectedItem.representName" type="text" style="width:96%" class="blue_input">
            </td>
            <td class="td_blue">
              <select v-model="selectedItem.inExType" class="country select_input01">
                <option :value="null">국내/국외 구분을 선택해주세요.</option>
                <option v-for="item in comboItems['1140']" :value="item.codeDetailId" :key="item.codeDetailId">{{ item.codeDetailName }}</option>
              </select>
              <select v-model="selectedItem.country" class="nation select_input02">
                <option :value="null">국가를 선택해주세요.</option>
                <template v-for="item in comboItems['2000']">
                  <option v-if="item.etc1 === selectedItem.inExType" :key="item.codeDetailId" :value="item.codeDetailId">{{ item.codeDetailName }}</option>
                </template>
              </select>
            </td>
            <td class="td_blue">
              <select v-model="selectedItem.region" class="select_input03">
                <option :value="null">지역구분를 선택해주세요.</option>
                <option v-for="item in comboItems['1250']" :value="item.codeDetailId" :key="item.codeDetailId">{{ item.codeDetailName }}</option>
              </select>
            </td>
            <td class="td_blue">
              <v-autocomplete
                v-model="selectedItem.industryType"
                :items="comboItems['7060']"
                outlined
                dense
                hide-details
                no-data-text="데이터를 찾을 수 없습니다."
                item-text="codeDetailName"
                item-value="codeDetailId"
                class="industry-type mx-auto"
                style="width: 96%;"
              ></v-autocomplete>
            </td>
            <td class="td_blue">
              <input v-model="selectedItem.chargerTel" type="tel" style="width:96%" class="blue_input">
            </td>
            <td class="td_blue">
              <input v-model="selectedItem.chargerCell" type="tel" style="width:96%" class="blue_input">
            </td>
          </tr>
          <tr>
            <td colspan="3" class="td_blue big">업체주소</td>
            <td colspan="3" class="td_blue big">사업자등록증</td>
          </tr>
          <tr>
            <td colspan="3" class="td_blue">
              <input v-model="selectedItem.custAddr" type="text" style="width:98%" class="blue_input">
            </td>
            <td colspan="3" class="td_blue text-left">
              <input type="file" style="width:82%" id="reg" :key="bizRegistPaperKey" @click="onClickBizRegPaper" @change="onChangeBizRegPaper">
              <div v-if="selectedItem.bizRegistPaper && selectedItem.bizRegistPaper.length > 0" class="contr">
                <p v-for="(paper, index) in selectedItem.bizRegistPaper" :key="index" class="p_files">
                  <a @click="downloadFile(paper)">{{ paper.fileName }}
                  </a>
                  <button type="button" @click="deleteBizRegPaper(index)">
                    <img :src="require('@/assets/img/close01.png')" alt="">
                  </button>
                </p>
              </div>
            </td>
          </tr>
          <tr>
            <td colspan="6" class="td_blue big">비고</td>
          </tr>
          <tr>
            <td colspan="6" class="td_blue">
              <textarea v-model="selectedItem.remark" cols="100%" rows="2" class="blue_input" style="width:99%"></textarea>
            </td>
          </tr>
          </tbody>
        </table>
        <div class="text-right py-2">
          <v-btn
            color="primary"
            elevation="0"
            @click="saveItem"
          >
            저장
          </v-btn>
        </div>
      </fieldset>
    </div>
  </v-container>
</template>

<script>
import TechPagination from '@/components/Pagination'
export default {
  name: 'LabManage',
  components: {TechPagination},
  data() {
    return {
      loading: false,
      param: {
        start: 1,
        end: 10,
        searchType: 'name',
        keyword: null,
        sort: ''
      },
      pageable: {
        page: 1,
        totalElements: 0
      },
      items: [],
      comboItems: {},
      selectedIndex: -1,
      selectedItem: {
        bizRegistPaper: []
      },
      bizRegistPaperKey: 0,
    }
  },
  watch: {
    'pageable.page' () {
      this.searchList()
    }
  },
  mounted() {
    this.getCodeDetails()
        .then(this.search)
  },
  methods: {
    search() {
      if (this.pageable.page === 1) {
        this.searchList()
      } else {
        this.pageable.page = 1
      }
    },
    searchList() {
      this.loading = true

      this.param.start = (this.pageable.page - 1) * 10
      this.param.end = this.pageable.page * 10

      this.$http.get('/api/sys/company', {params: this.param})
      .then(({ data }) => {
        this.selectedIndex = -1
        this.selectedItem = {}
        this.items = data
        if (data.length > 0) {
          this.pageable.totalElements = data[0].totalElements
        } else {
          this.pageable.totalElements = 0
        }
        this.loading = false
      })
    },
    itemSelect(item, index) {
      this.selectedIndex = index
      // this.selectedItem = item
      this.$http.get(`/api/sys/company/${item.dbType}/${item.custCode}`)
        .then(({ data }) => {
          this.selectedItem = data
        })
    },
    saveItem() {
      this.$http.post('/api/sys/company', this.selectedItem)
          .then(({ data }) => {
            if (data === 'success') {
              alert('저장되었습니다.')
              this.search()
            }
          })
    },
    getDbType(dataType) {
      switch (dataType) {
        case 'COMP': return '기술이전'
        case 'AGENCY': return '중개기관'
        case 'CHARGE': return '제비용'
        default: return ''
      }
    },
    setSort(fieldName) {
      const sort = this.param.sort
      if (sort.indexOf(fieldName) > -1) {
        if (sort.indexOf(',desc') > 0) {
          this.param.sort = ''
        } else {
          this.param.sort = `${fieldName},desc`
        }
      } else {
        this.param.sort = `${fieldName},asc`
      }
      this.search()
    },
    checkSort(fieldName) {
      const sort = this.param.sort
      if (sort.indexOf(fieldName) > -1) {
        if (sort.indexOf(',desc') > 0) {
          return 'mdi-arrow-down'
        } else {
          return 'mdi-arrow-up'
        }
      } else {
        return ''
      }
    },
    getCodeDetails() {
      this.loading = true
      return this.$http.get('/api/sys/code/detail/all')
          .then(({ data }) => {
            this.comboItems = data
          })
          .finally(() => this.loading = false)
    },
    onClickBizRegPaper(e) {
      if (!this.selectedItem.custCode) {
        alert('업체정보를 먼저 입력해 주세요')
        e.preventDefault()
        e.stopPropagation()
      }
    },
    onChangeBizRegPaper(e) {
      this._uploadFiles(e)
        .then(({ data }) => {
          this.bizRegistPaperKey++
          if (data.length > 0) {
            let seq = 0
            if (this.selectedItem.bizRegistPaper && this.selectedItem.bizRegistPaper.length > 0) {
              seq = this.selectedItem.bizRegistPaper.length
            } else {
              this.selectedItem.bizRegistPaper = []
            }
            for (let i = 0; i < data.length; i++) {
              this.selectedItem.bizRegistPaper.push({
                tempKey: data[i].tempKey,
                fileName: `사업자등록증_${this.selectedItem.custName}_${seq + (i + 1)}.${data[i].fileExtension}`,
                fileExtension: data[i].fileExtension,
                seq: seq + (i + 1)
              });
            }
          }
        })
    },
    _uploadFiles(e) {
      const formData = new FormData()
      for (let i = 0; i < e.target.files.length; i++) {
        formData.append('files', e.target.files[i])
      }

      return this.$http.post('/api/file/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .catch((error) => {
          console.error(error)
          alert('오류가 발생하였습니다.')
        })
    },
    downloadFile(fileItem) {
      let url = `/api/sys/company/files/download/${fileItem.fileId}`

      if (!fileItem.fileId) {
        url = `/api/file/download/temp/${fileItem.tempKey}`
      }
      this.$http.post(url, {},{
        responseType: "blob"
      }).then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileItem.fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        this.downloadDialog = false
      }).catch(() => {
        alert("파일 다운로드 실패")
        this.downloadDialog = false
      })
    },
    deleteBizRegPaper(index) {
      this.selectedItem.bizRegistPaper.splice(index, 1)
    },
  }
}
</script>

<style scoped>
.pointer {
  cursor: pointer;
}
</style>
